.theme-card{
    height:12rem
}

.theme-card-text{
    color:white !important;
}

.theme-card-image-holder{
    height: 8rem;
}

.theme-card-image{
    height: 100%;
    width: 100%;
    object-fit: cover;
}