.slick-track {
    margin: none !important;
}

.slick-slide {
    padding: 0 .25rem;
    margin: 0px;
}


.slick-slide img {
    max-width: 100%;
    max-height: 100%;
}

.custom-arrow {
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-right: 20px;
    margin-left: 20px;
}

@media only screen and (min-width: 800px) {
    .slick-slider {
        margin-left: 10rem;
        margin-right: 10rem;
    }
}

.slick-list {
    height: 100%;
}
.slick-prev,
.slick-next {
    /* Ensure the arrows are visible */
    background-color: #2e2e2e !important;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: 1;
    color: #2e2e2e;
    /* Change this as needed */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before {
    content: '←';
    /* This is a left-pointing arrow */
}

.slick-next:before {
    content: '→';
    /* This is a right-pointing arrow */
}
.slick-prev {
    left: 0px !important;
    /* Adjust as necessary */
    z-index: 2;
    /* background-color: red !important; */
}

.slick-next {
    right: 0px !important;
    /* Adjust as necessary */
    z-index: 2;
    /* background-color: red !important; */
}
.customer-content {
    text-align: center;
}


