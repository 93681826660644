html{
}
body {
    background-color: white !important;
}
.footer{
    padding: 24px 0 24px 250px;
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;
}
.footer .nav {
    display: inline-block;
    float: center;
    margin-bottom: 0;
    padding-left: 30px;
    list-style: none;
    align-items: center;
    
}

.footer .container-fluid {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;
}

.main-panel-customer p, h1, h2, h3, h4, h5, h6, h7, h8, h9, h0 {
    color: black !important;
}

.h1{
    color: #2e2e2e;
}

.booking-row {
    justify-content: center;
    align-content: center;
}

* {
    font-family: 'playfair display', serif;
    color: #2e2e2e;
}

.ladder-text {
    font-size: large;
}

.theme-card-row{
    object-position: center;
    place-content: center;
    height: fit-content;
}

.theme-card-holder{
    padding-top: 1rem;
}
