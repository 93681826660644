.addon-card{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 20px;
    padding: 10px;
    margin: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: fit-content;
    height: 300px;
    background-color: white;
}


.addon-card-header {
    height: 10%;
}

.addon-card-body {
    height: 85%;
}

.addon-card-image{
    height:100%;
    width:min-content;
}

.addon-card-image:hover {
    opacity: 0.7;
}

#myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.modal{
    display: none;
    position: fixed;
        /* Stay in place */
        z-index: 1;
        /* Sit on top */
        padding-top: 100px;
        /* Location of the box */
        left: 0;
        top: 0;
        width: 100%;
        /* Full width */
        height: 100%;
        /* Full height */
        overflow: auto;
        /* Enable scroll if needed */
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(0, 0, 0, 0.9);
        /* Black w/ opacity */
}

.modal-content {
    margin: auto;
    display: block;
    height: 80% !important;
    width: auto !important;
     /* max-width: 90vw !important;  */
}

#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ffffff;
    padding: 10px 0;
    height: 150px;
}

.modal-content,
#caption {
    animation-name: zoom;
    animation-duration: 0.6s;
}

@keyframes zoom {
    from {
        transform: scale(0.1)
    }

    to {
        transform: scale(1)
    }
}

/* The Close Button */
.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #ffffff !important;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #ffffff !important;
    text-decoration: none;
    cursor: pointer;
}
/* @media only screen and (max-width: 700px) {
    .modal-content {
        width: 100% !important;
        height: auto !important;
    }
} */

.addon-card-title{
    /* font-size: 1.5rem; */
    font-family: 'Apple Chancery',
        cursive !important;
    font-weight: bold;
    margin-bottom: 0;
}