.faq-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    width: 80%;
    justify-self: center;
}

.faq-header-holder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}

.faq-header{
    margin-top: 1rem;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0px;   
}

.faq-question{
    margin-top: 1rem !important;
    margin-bottom: 0px !important;
}
.faq-question h1, h2, h3, h4, h5, h6{
    margin-bottom: 0px !important;
}
.faq-question p {
    margin-bottom: 0px !important;
}

.question-holder{

}

.question-text{

}

.answer-holder{

}

.answer-text{

}

.addendum{
    margin-top: 2rem;
}