.about-us-main-image {
    width: 100%;
    height: 40rem;
    object-fit: cover;
    object-position: center;
    position: relative;
    z-index: 1;
    
}

.about-us-info {
    font-family: cursive;
    width: 80%;
    margin-left: 10%;
}

.about-us-header {
    font-family: cursive;
}