.customer-content{
    text-align: center;
}

.contact-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.max-400 {
    max-height: 400px;
    width: fit-content;
    @media only screen and (max-width:500px) {
        /* visibility: hidden;
        width:0rem; */
    }
}

.customer-info {
    color:black;
    max-width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
}

.subpoints {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.contact-cont {
    display: flex;
    flex-direction: column;
    max-width: min-content;
    padding: 1rem 16rem;
    border-radius: .5rem;
    height: 100%;
}

.align-center {
    align-items: center;
}

.input-box {
    border-radius: .5rem;
    border: none;
    opacity: 50%;
    padding-left: .5rem;
    padding-right: .5rem;
}

.submit-button {
    border-radius: .5rem;
    border: none;
    color: white;
    background-color: #2e2e2e;
}
.submit-button:hover {
    opacity: 90%;
}

.pt-75 {
    padding-top: 7.5rem;
}

* {
    font-family: 'playfair display', serif;
    color: #2e2e2e;
}

h3 {
    margin-bottom: 0px !important;
}

h4 {
    opacity: 60%;
}

.dropdown {
    display: flex;
    bottom: .4rem;
    place-content: center;
}

/* .col {
    display: flex;
    align-items: baseline;
    justify-content: center;
} */

.whats-included-div {
    
}

.mobile {
    display: none !important;
}

@media (max-width: 768px) {
    .mobile {
        display: block !important;
    }
    .desktop {
        display: none !important;
    }
    .contact-cont {
        padding: 1rem 1rem;
    }
}