.customer-header {
  padding-top: 2rem;
  /* padding-right: 4rem;
  padding-left: 4rem; */
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.flex {
  display: flex;
}

.row-padding {
  padding: .5rem 0;
}

.pinkbg {
  background-color: #ffe6e6;
}

.title {
font: normal normal normal 32px/1.4em 'playfair display',
    serif;
  font-size: 3.2em;
  color: #0e0202;
  margin-bottom: 0.1em;
  margin-top: 0em;
    padding-top: 0;
    font-weight: lighter;
 place-self: center;   
}

.subtitle {
  font: normal normal normal 16px/1.4em 'playfair display',
    serif;
  font-size: 1.5em;
  font-weight: lighter;
    /* background: #f1d3d3; */
   margin-bottom: 0.1em;
}

.title-location {
  font: normal normal normal 16px/1.4em 'playfair display',
    serif;
  font-size: 1em;
  /* font-weight: bold; */
  color: #0e0202;
  margin-bottom: 0.1em;
  margin-top: 0em;
    padding-top: 0
}

.customer-routing-links {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #181a1b;
    font-size: fit-content;
    transition: color 0.4s ease 0s;
    font-weight: bold;
    text-wrap: nowrap;
    border-bottom: .25rem solid transparent;
}

.theme-customer-header-link {
      display: flex;
        justify-content: center;
        align-items: center;
        color: #181a1b;
        transition: color 0.4s ease 0s;
        font-weight: bold;
}

.theme-header-text{
          color: #181a1b;
            transition: color 0.4s ease 0s;
            font-weight: bold;
}

.customer-routing-links:hover {
    border-bottom: .25rem solid #181a1b;
    color: #2e2e2e;
    transition: color 0.4s ease 0s;
}

.customer-routing-links.dropdown:hover {
  margin-bottom: 0;
}

.book-now-button {
    background-color: #ffffff !important;
    color: rgb(0, 0, 0) !important;
    background-image: none !important;
    border: none;
    border-radius: 0.5em;
    padding: 0.5em 1em;
    margin-top: 1em;
    font: 'playfair display', serif;
    font-size: 1em;
    transition: background-color 0.4s ease 0s;
    float: right;
    position: relative;
    
}

.header-book-now-holder {

    text-align: right;;
}

.customer-dropdown-header {
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    margin-top:0 !important;
    padding-top: 0 !important;
}


.nav-link{
  padding: 0;
}

.dropdown{
  display:flex !important;
  position: static !important;
}
.customer-dropdown-header{
  width: 100%;
}

.dropdown-menu:before{
  left:50% !important;
}

@media (max-width: 480px) {
  .dropdown-menu:before{
    left: 10% !important;
  } 
}